import type { Stream, RequestOptions } from "@dxos/codec-protobuf";
import substitutions from "../../../substitutions.js";
import * as dxos_agent_dashboard from "../agent/dashboard.js";
import * as dxos_agent_echoproxy from "../agent/echoproxy.js";
import * as dxos_agent_epoch from "../agent/epoch.js";
import * as dxos_agent_functions from "../agent/functions.js";
import * as dxos_bot from "../bot.js";
import * as dxos_client_services from "../client/services.js";
import * as dxos_config from "../config.js";
import * as dxos_devtools_host from "../devtools/host.js";
import * as dxos_devtools_swarm from "../devtools/swarm.js";
import * as dxos_echo_blob from "../echo/blob.js";
import * as dxos_echo_feed from "../echo/feed.js";
import * as dxos_echo_filter from "../echo/filter.js";
import * as dxos_echo_indexing from "../echo/indexing.js";
import * as dxos_echo_metadata from "../echo/metadata.js";
import * as dxos_echo_model_document from "../echo/model/document.js";
import * as dxos_echo_model_messenger from "../echo/model/messenger.js";
import * as dxos_echo_model_text from "../echo/model/text.js";
import * as dxos_echo_object from "../echo/object.js";
import * as dxos_echo_query from "../echo/query.js";
import * as dxos_echo_service from "../echo/service.js";
import * as dxos_echo_snapshot from "../echo/snapshot.js";
import * as dxos_echo_timeframe from "../echo/timeframe.js";
import * as dxos_edge_messenger from "../edge/messenger.js";
import * as dxos_error from "../error.js";
import * as dxos_gravity from "../gravity.js";
import * as dxos_halo_credentials from "./credentials.js";
import * as dxos_halo_credentials_auth from "./credentials/auth.js";
import * as dxos_halo_credentials_greet from "./credentials/greet.js";
import * as dxos_halo_credentials_identity from "./credentials/identity.js";
import * as dxos_halo_invitations from "./invitations.js";
import * as dxos_halo_keyring from "./keyring.js";
import * as dxos_halo_signed from "./signed.js";
import * as dxos_iframe from "../iframe.js";
import * as dxos_keys from "../keys.js";
import * as dxos_mesh_bridge from "../mesh/bridge.js";
import * as dxos_mesh_broadcast from "../mesh/broadcast.js";
import * as dxos_mesh_messaging from "../mesh/messaging.js";
import * as dxos_mesh_muxer from "../mesh/muxer.js";
import * as dxos_mesh_presence from "../mesh/presence.js";
import * as dxos_mesh_protocol from "../mesh/protocol.js";
import * as dxos_mesh_replicator from "../mesh/replicator.js";
import * as dxos_mesh_signal from "../mesh/signal.js";
import * as dxos_mesh_swarm from "../mesh/swarm.js";
import * as dxos_mesh_teleport from "../mesh/teleport.js";
import * as dxos_mesh_teleport_auth from "../mesh/teleport/auth.js";
import * as dxos_mesh_teleport_automerge from "../mesh/teleport/automerge.js";
import * as dxos_mesh_teleport_blobsync from "../mesh/teleport/blobsync.js";
import * as dxos_mesh_teleport_control from "../mesh/teleport/control.js";
import * as dxos_mesh_teleport_gossip from "../mesh/teleport/gossip.js";
import * as dxos_mesh_teleport_notarization from "../mesh/teleport/notarization.js";
import * as dxos_mesh_teleport_replicator from "../mesh/teleport/replicator.js";
import * as dxos_registry from "../registry.js";
import * as dxos_rpc from "../rpc.js";
import * as dxos_service_agentmanager from "../service/agentmanager.js";
import * as dxos_service_publisher from "../service/publisher.js";
import * as dxos_service_supervisor from "../service/supervisor.js";
import * as dxos_service_tunnel from "../service/tunnel.js";
import * as dxos_tracing from "../tracing.js";
import * as dxos_type from "../type.js";
import * as dxos_value from "../value.js";
import * as example_testing_data from "../../example/testing/data.js";
import * as example_testing_rpc from "../../example/testing/rpc.js";
import * as google_protobuf from "../../google/protobuf.js";
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/keys.proto}
 */
export enum KeyType {
    UNKNOWN = 0,
    IDENTITY = 1,
    DEVICE = 2,
    SPACE = 3,
    FEED = 4,
    DXNS_ADDRESS = 5
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/keys.proto}
 */
export interface KeyRecord {
    /**
     * The `KeyType` type of the key. This is often unknown for keys from other sources.
     */
    type: KeyType;
    /**
     * The public key as a Buffer (required).
     */
    publicKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * The secret key as a Buffer (this will never be visible outside the Keyring).
     *
     * Options:
     *   - proto3_optional = true
     */
    secretKey?: ReturnType<(typeof substitutions)["dxos.keys.PrivateKey"]["decode"]>;
    /**
     * Is this key from a Greeting "hint"?
     *
     * Options:
     *   - proto3_optional = true
     */
    hint?: boolean;
    /**
     * Determines if this is our key?
     * Usually true if `secret_key` is present; may be false for "inception keys" such as the Space key.
     *
     * Options:
     *   - proto3_optional = true
     */
    own?: boolean;
    /**
     * Is this key to be trusted?
     *
     * Options:
     *   - proto3_optional = true
     */
    trusted?: boolean;
    /**
     * An RFC-3339 date/time string for when the key was added to the Keyring.
     *
     * Options:
     *   - proto3_optional = true
     */
    added?: string;
    /**
     * An RFC-3339 date/time string for when the key was created.
     *
     * Options:
     *   - proto3_optional = true
     */
    created?: string;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/keys.proto}
 */
export interface KeyRecordList {
    keys?: KeyRecord[];
}
